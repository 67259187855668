import React from 'react';
import { PanelRow, Subtitle, Title } from '../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../common/utils';
import { StyledPanelRowReverse, StyledSkipInvite } from './StyledSkipInvite';


const TextPathPrefix = 'app.pages.forms.triggeringModal.triggerByTargeting.skipInvite';
const title = getSingleText(`${TextPathPrefix}.title`);
const appearDirectly = getSingleText(`${TextPathPrefix}.appearDirectly`);
const highPriorityFeedback = getSingleText(`${TextPathPrefix}.highPriorityFeedback`);
const noIntegration = getSingleText(`${TextPathPrefix}.noIntegration`);
const note = getSingleText(`${TextPathPrefix}.note`);


export const SkipInvite = () => {
  return (
    <StyledSkipInvite>
      <PanelRow>
        <Title addMargin>{ title }</Title>
        <Subtitle marginTop={1}>- { appearDirectly }</Subtitle>
        <Subtitle marginTop={1}>- { highPriorityFeedback }</Subtitle>
        <Subtitle marginTop={1}>- { noIntegration }</Subtitle>
      </PanelRow>
      <StyledPanelRowReverse>
        <Subtitle>{ note }</Subtitle>
      </StyledPanelRowReverse>
    </StyledSkipInvite>
  );
};