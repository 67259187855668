const DocsBaseURL = 'https://docs.medallia.com/';
export const Links = {
  INVITE_TYPES: {
    BANNER: `${DocsBaseURL}?resourceId=mds-invite-banner`,
    ALERT: `${DocsBaseURL}?resourceId=mds-invite-native-alert`,
    LOCAL_NOTIFICATION: `${DocsBaseURL}?resourceId=mds-invite-local-notification`,
    PUSH_NOTIFICATION: `${DocsBaseURL}?resourceId=mds-invite-push-notification`,
    CUSTOM: `${DocsBaseURL}?resourceId=mds-invite-custom-intercept`,
    SKIP_INVITE: `${DocsBaseURL}?resourceId=mds-invite-skip-invite`,
  },
  TRIGGER_TYPES: `${DocsBaseURL}?resourceId=mds-trigger-types`,
  CODE_TRIGGER: `${DocsBaseURL}?resourceId=mds-invite-code`,
};
