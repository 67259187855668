import { LocalNotification } from './LocalNotification';
import React, { useContext } from 'react';
import { TriggerContext } from '../../..';
import Banner from './Banner';
import CustomInvite from './CustomInvite';
import Alert from './Alert';
import PushNotification from './PushNotification';
import { SkipInvite } from './SkipInvite';

export const TARGETING_TYPES = {
  BANNER: 'BANNER',
  ALERT: 'ALERT',
  LOCAL_NOTIFICATION: 'LOCAL_NOTIFICATION',
  CUSTOM_INTERCEPT: 'CUSTOM',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  SKIP_INVITE: 'SKIP_INVITE',
};

const TriggerComponentsMap = {
  [TARGETING_TYPES.BANNER]: Banner,
  [TARGETING_TYPES.CUSTOM_INTERCEPT]: CustomInvite,
  [TARGETING_TYPES.ALERT] : Alert, 
  [TARGETING_TYPES.LOCAL_NOTIFICATION] : LocalNotification,
  [TARGETING_TYPES.PUSH_NOTIFICATION] : PushNotification,
  [TARGETING_TYPES.SKIP_INVITE] : SkipInvite,

};
const TriggerTypes = () => {
  const { triggerState } = useContext(TriggerContext);
  const TriggerTypeComponent = TriggerComponentsMap[triggerState.trigger.invitationType];
  return <TriggerTypeComponent  />;
};

export default TriggerTypes;
