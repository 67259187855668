import React, { useContext } from 'react';
import LivePreview from './LivePreview';
import { TriggerContext } from '../../..';
import CustomInterceptPreview from './CustomInterceptPreview';
import { TARGETING_TYPES } from '../TargetingTypes/index';
import PushNotificationPreview from './PushNotificationPreview';

const PreviewImageMap = {
  [TARGETING_TYPES.BANNER]: LivePreview,
  [TARGETING_TYPES.ALERT]: LivePreview,
  [TARGETING_TYPES.LOCAL_NOTIFICATION]: LivePreview,
  [TARGETING_TYPES.CUSTOM_INTERCEPT] : CustomInterceptPreview,
  [TARGETING_TYPES.PUSH_NOTIFICATION]: PushNotificationPreview,
  [TARGETING_TYPES.SKIP_INVITE]: CustomInterceptPreview,
};

export const TargetingPreview = () => {
  const { triggerState } = useContext(TriggerContext);
  const renderTargetingImage = () => {
    const TargetingPreviewComponent = PreviewImageMap[triggerState.trigger.invitationType] || LivePreview;
    return <TargetingPreviewComponent />;
  };
  return (
    <React.Fragment>
      {renderTargetingImage()}
    </React.Fragment>
  );
};

export default TargetingPreview;
