import styled from 'styled-components';
import { units } from '@m/alchemy-ui';
import { PanelRow } from '../../../../StyledTriggeringModal';

export const StyledSkipInvite = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: ${units(42)};
  
`;

export const StyledPanelRowReverse = styled(PanelRow)`
  flex-direction: column-reverse;
`;
